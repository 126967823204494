import { useCallback, useEffect, useState } from 'react';
import { useFetcher } from '@remix-run/react';
import { useDebounceFn } from 'ahooks';
import { type SupportedCountry, FindPlaceTypeEnum } from 'generated/graphql';
import {
    FIND_PLACE_BY_KEYWORD_ACTION,
    type loader,
} from '~/routes/actions.location.find-place-by-keyword';
import type { DetailedPlace } from '@modules-rocco/location/type/detailed-place';

interface UsePlaceFinderProps {
    type?: FindPlaceTypeEnum;
    country: SupportedCountry;
}

export const usePlaceFinder = (props: UsePlaceFinderProps) => {
    const { type = FindPlaceTypeEnum.SuburbMixed, country } = props;

    const fetcher = useFetcher<typeof loader>();
    const [places, setPlaces] = useState<DetailedPlace[]>([]);
    const searchCountry = country;

    const updateKeyword = useCallback(
        async (keyword: string, limit: number) => {
            if (!keyword || keyword.trim().length < 2) {
                return;
            }

            if (fetcher.state === 'idle') {
                const formData = new FormData();

                formData.append('keyword', keyword.trim());
                formData.append('type', type.toString());
                formData.append('limit', limit.toString());
                formData.append('country', searchCountry.toString());

                fetcher.submit(formData, {
                    method: 'get',
                    action: FIND_PLACE_BY_KEYWORD_ACTION,
                });
            }
        },
        [searchCountry, fetcher, type]
    );

    const { run: handlePlaceFind } = useDebounceFn(updateKeyword, {
        wait: 500,
    });

    const resetPlaces = useCallback(() => {
        setPlaces([]);
    }, []);

    useEffect(() => {
        // TODO: do we need to check for duplicated places?
        setPlaces(fetcher?.data ?? []);
    }, [fetcher.data]);

    return {
        places,
        loading: fetcher.state === 'loading' || fetcher.state === 'submitting',
        handlePlaceFind,
        resetPlaces,
    };
};
