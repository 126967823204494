import React, { useEffect, useRef } from 'react';
import { Link } from '@remix-run/react';
import { Button as RoccoButton } from '@rocco/components/button';
import type { FilterTabViewModel } from '@rocco/ui/filter-sort/view-models/filter-tab';
import { DisplayMode } from '@rocco/types/enum/display-mode';

interface FilterTabsProps {
    tabs: FilterTabViewModel[];
    displayMode?: DisplayMode;
    tabContainerRef?: React.RefObject<HTMLDivElement>;
    onTabClick?: (tab: FilterTabViewModel) => void;
}

export const FilterTabs = ({
    tabs,
    tabContainerRef,
    onTabClick,
}: FilterTabsProps) => {
    const activeTabRef = useRef<HTMLButtonElement>(null);
    const activeTabIndex = tabs.findIndex(tab => tab.selected) || 0;

    useEffect(() => {
        // Function to handle scrolling
        const scrollToActiveTab = () => {
            if (tabContainerRef?.current && activeTabRef.current) {
                tabContainerRef.current.scrollTo({
                    // 120px is the width of the filter button
                    left: activeTabRef.current.offsetLeft - 120,
                    behavior: 'smooth',
                });
            }
        };
        // Also try after a short delay to handle any delayed DOM updates
        const timeout = setTimeout(scrollToActiveTab, 150);

        return () => clearTimeout(timeout);
    }, [tabContainerRef, activeTabRef, activeTabIndex]);

    return tabs.length > 0 ? (
        <div className="flex items-center gap-[0.375rem] md:gap-2 bg-background">
            {tabs.map(tab => (
                <RoccoButton
                    key={tab.id}
                    color={tab.selected ? 'dark' : 'secondary'}
                    ref={tab.selected ? activeTabRef : null}
                    onClick={() => onTabClick?.(tab)}
                    asChild
                >
                    <Link
                        className="text-sm leading-[1.625rem] tracking-0 md:text-base md:tracking-0"
                        to={tab.link}
                        preventScrollReset
                    >
                        {tab.title}
                    </Link>
                </RoccoButton>
            ))}
        </div>
    ) : null;
};
