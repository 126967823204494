import type { LoaderFunctionArgs } from '@remix-run/node';
import { findPlaceByKeyword } from '@modules-rocco/location/api/find-place-by-keyword.server';
import { getCountryCodeByName } from '@modules-rocco/location/util/get-name-by-country';

export const FIND_PLACE_BY_KEYWORD_ACTION =
    '/actions/location/find-place-by-keyword';

export const loader = async (args: LoaderFunctionArgs) => {
    const { request, context } = args;

    const url = new URL(request.url);

    const supportedCountry = getCountryCodeByName(
        url.searchParams.get('country') || context.localisation.country
    );

    if (!supportedCountry) {
        return null;
    }

    return findPlaceByKeyword(
        {
            keyword: url.searchParams.get('keyword') ?? '',
            type: url.searchParams.get('type') ?? '',
            limit: parseInt(url.searchParams.get('limit') ?? '10'),
            country: supportedCountry,
        },
        context
    );
};
