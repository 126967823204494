import { useFetcher } from '@remix-run/react';
import { useLatest } from 'ahooks';
import { useCallback, useEffect, useState } from 'react';
import type { DetailedPlace } from '@modules-rocco/location/type/detailed-place';
import {
    FIND_PLACE_BY_COORDINATES_ACTION,
    type loader,
} from '~/routes/actions.location.find-place-by-coordinates';
import { useCurrentCoordinates } from '@ui-rocco/hooks/location/use-current-coordinates';
import { isValidCoordinates } from '@archipro-website/web/app/modules/professional/hook/use-locations-sort-by-distance';

interface UseCurrentLocationProps {
    currentLocationDisabled: boolean;
    currentLocation: DetailedPlace | null;
    loading: boolean;
    getCurrentLocation: () => void;
}

export const useCurrentLocation = (): UseCurrentLocationProps => {
    const {
        userCoordinates,
        findUserCoordinates,
        loaded: coordinatesLoaded,
    } = useCurrentCoordinates();

    const fetcher = useFetcher<typeof loader>();
    const locationFetcher = useLatest(fetcher);
    const [loading, setLoading] = useState(false);

    const fetchCurrentLocation = useCallback(() => {
        if (!coordinatesLoaded) return;
        if (!userCoordinates || !isValidCoordinates(userCoordinates)) {
            setLoading(false);
            return;
        }

        locationFetcher.current.submit(
            {
                longitude: userCoordinates.Longitude.toString(),
                latitude: userCoordinates.Latitude.toString(),
            },
            {
                method: 'get',
                action: FIND_PLACE_BY_COORDINATES_ACTION,
            }
        );
    }, [coordinatesLoaded, locationFetcher, userCoordinates]);

    useEffect(() => {
        if (locationFetcher.current.state === 'idle') {
            setLoading(false);
        }
    }, [
        locationFetcher,
        locationFetcher.current.data,
        locationFetcher.current.state,
    ]);

    useEffect(() => {
        if (coordinatesLoaded) {
            fetchCurrentLocation();
        }
    }, [userCoordinates, coordinatesLoaded, fetchCurrentLocation]);

    const getCurrentLocation = useCallback(() => {
        setLoading(true);
        findUserCoordinates();
    }, [findUserCoordinates]);

    return {
        currentLocationDisabled:
            coordinatesLoaded && !isValidCoordinates(userCoordinates),
        currentLocation: fetcher?.data ?? null,
        getCurrentLocation,
        loading,
    };
};
