import type { LoaderFunction } from '@remix-run/node';
import type { DataFunctionArgs } from '@modules-rocco/root';
import { findPlaceByKCoordinates } from '@modules-rocco/location/api/find-place-by-coordinates.server';

export const FIND_PLACE_BY_COORDINATES_ACTION =
    '/actions/location/find-place-by-coordinates';

export const loader: LoaderFunction = async ({
    context,
    request,
}: DataFunctionArgs) => {
    const url = new URL(request.url);
    const searchParams = url.searchParams;

    const longitudeStr = searchParams.get('longitude');
    const latitudeStr = searchParams.get('latitude');
    if (!longitudeStr || !latitudeStr) return null;

    const longitude = Number(longitudeStr);
    const latitude = Number(latitudeStr);
    if (isNaN(longitude) || isNaN(latitude)) return null;

    return findPlaceByKCoordinates(
        {
            latitude,
            longitude,
        },
        context
    );
};
