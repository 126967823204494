import { useCallback, useState } from 'react';

interface Coordinates {
    Latitude: number;
    Longitude: number;
}

interface IUseCurrentUserCoordinates {
    userCoordinates: Coordinates;
    findUserCoordinates: () => void;
    loaded: boolean;
    loading: boolean;
}

export const useCurrentCoordinates = (
    coordinates: Coordinates = { Latitude: 0, Longitude: 0 },
    skip?: boolean
): IUseCurrentUserCoordinates => {
    const [userCoordinates, setUserCoordinates] =
        useState<Coordinates>(coordinates);

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);

    const findUserCoordinates = useCallback(async () => {
        setLoaded(false);

        if (skip) {
            setLoaded(true);
            return;
        }

        setLoading(true);

        let userGeoCoords: Coordinates | null = null;

        // Get the visitor's current position from browser first, this is the most accurate
        try {
            userGeoCoords = await getCoordinatesByBrowser();
        } catch (error) {
            console.log(
                "Get current visitor's coordinates by browser result: ",
                error
            );
        }

        // Todo:  get coordinates from other methods
        // e.g.from user's region, cloudflare region cookies, ip address, etc.

        if (
            userGeoCoords &&
            (userGeoCoords.Latitude !== 0 || userGeoCoords.Longitude !== 0)
        ) {
            setUserCoordinates(userGeoCoords);
        }

        setLoaded(true);
        setLoading(false);
    }, [skip]);

    return { userCoordinates, findUserCoordinates, loaded, loading };
};

const getCoordinatesByBrowser = async (): Promise<Coordinates | null> => {
    if (navigator?.geolocation) {
        const navGetCurrentPosition: Promise<Coordinates> = new Promise(
            (resolve, reject) => {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        resolve({
                            Latitude: position.coords.latitude,
                            Longitude: position.coords.longitude,
                        });
                    },
                    (error) => reject(error),
                    {
                        enableHighAccuracy: false,
                        timeout: 15000,
                        maximumAge: 1000,
                    }
                );
            }
        );

        const userGeoCoords = await navGetCurrentPosition;

        if (userGeoCoords) {
            return userGeoCoords;
        }
    }

    return null;
};
