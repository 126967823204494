import type { LocationPlace } from '@rocco/ui/location/types/location-place';
import { LocationPlaceType } from '@rocco/ui/location/types/enum/location-place-type';

// Add new enum for location levels
export enum LocationLevel {
    Suburb = 'suburb',
    District = 'district',
    City = 'city',
    Region = 'region',
    State = 'state',
    All = 'all',
}

export const formatLocation = (
    location: LocationPlace | null,
    level: LocationLevel = LocationLevel.All,
): string => {
    if (!location) {
        return '';
    }

    const {
        suburb,
        district,
        city,
        region,
        state,
        placeType,
        fullAddress,
        latitude,
    } = location;

    if (!suburb && !district && !city && !region && !state && !fullAddress) {
        return '';
    }

    // Early return for district type or when fullAddress should be used
    if (
        (placeType === LocationPlaceType.District ||
            (!suburb && !district && !city)) &&
        fullAddress
    ) {
        return fullAddress;
    }

    if (level !== LocationLevel.All) {
        const locationMap: Record<LocationLevel, string | undefined> = {
            [LocationLevel.Suburb]:
                suburb || district || city || region || state,
            [LocationLevel.District]: district || city || region || state,
            [LocationLevel.City]: city || region || state,
            [LocationLevel.Region]: region || state,
            [LocationLevel.State]: state,
            [LocationLevel.All]: suburb || district || city || region || state,
        };

        return locationMap[level] || '';
    }

    // Original logic for LocationLevel.All
    let locationString = [suburb, district || city, suburb ? '' : region]
        .filter(Boolean)
        .join(', ');

    const shouldAddRegion = region && !locationString.includes(region);
    const shouldAddState = !region && state && !locationString.includes(state);

    if (shouldAddRegion) {
        locationString = `${locationString}, ${region}`;
    } else if (shouldAddState) {
        locationString = `${locationString}, ${state}`;
    }

    return latitude ? locationString : `${locationString} *`;
};
